<template>
  <ig-page-base
    :loading="loading"
    :is-boxed="true"
    :title="$t('pageResetPassword.title')"
    class="page-reset-password">

    <ig-form-schema
      :schema="customSchema"
      @submit="onFormSubmit">
      <template slot="actions">
        <div class="field">
          <div class="buttons page-reset-password__buttons">
            <router-link
              :to="{ name: 'page-login' }"
              class="button is-text has-text-weight-bold">
              {{ $t('pageResetPassword.form.cancel') }}
            </router-link>

            <b-button
              class="is-uppercase has-text-weight-bold"
              type="is-primary"
              native-type="submit">
              {{ $t('pageResetPassword.form.submit') }}
            </b-button>
          </div>
        </div>
      </template>
    </ig-form-schema>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgFormSchema } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('resetPassword');

export default Vue.extend({
  name: 'page-reset-password',
  components: {
    IgPageBase,
    IgFormSchema,
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    customSchema() {
      return {
        fields: [
          {
            name: 'password',
            componentPrefix: 'ig',
            component: 'password',
            fieldAttrs: {
              label: this.$t('pageResetPassword.form.password.label'),
            },
            attrs: {
              placeholder: this.$t('pageResetPassword.form.password.placeholder'),
              'icon-pack': 'fas',
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pageResetPassword.form.password.label'),
              rules: 'required|min:8|numbers|letters',
              vid: 'password',
              'data-vv-as': this.$t('pageResetPassword.form.password.label'),
            },
          },
          {
            name: 'confirmPassword',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageResetPassword.form.confirmPassword.label'),
            },
            attrs: {
              placeholder: this.$t('pageResetPassword.form.confirmPassword.placeholder'),
              'icon-pack': 'fas',
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.confirmPassword.label'),
              customMessages: {
                confirmed: this.$t('pageRegister.form.firstStep.confirmPassword.confirmed'),
              },
              rules: 'required|confirmed:password',
              'data-vv-as': this.$t('pageRegister.form.firstStep.password.label'),
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      'resetPassword',
      'checkToken',
    ]),
    onFormSubmit({ password }) {
      const { token } = this.$route.params || {};

      return this.resetPassword({ token, password })
        .then(() => this.$router.push({ name: 'page-login' }));
    },
  },
  beforeMount() {
    const { token } = this.$route.params || {};

    this.checkToken(token).then((ok) => {
      if (!ok) {
        this.$buefy.toast.open({
          message: this.$t('pageResetPassword.invalidToken'),
          type: 'is-danger',
          position: 'is-bottom',
        });

        this.$router.push({ name: 'page-forgot-password' });
      }
    });
  },
});
</script>

<style lang="scss" scoped>
.page-reset-password {
  width: 100%;

  &__buttons {
     display: flex;
     justify-content: space-between;
     margin-top: 1.5rem;
  }
}
</style>
